<template>
  <div class="">

    <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="-200 -200 900 900"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <path
        style="fill: #fff"
        d="M338.598,332.628c-7.621,0-13.798-6.179-13.798-13.798V200.871
	c-0.29-6.155-0.426-34.547-0.473-46.951c0-25.727-8.128-50.991-23.507-73.109l-32.531-46.789c-2.838-4.083-7.318-6.424-12.29-6.424
	c-4.971,0-9.452,2.341-12.29,6.423l-32.531,46.79c-15.379,22.118-23.507,47.382-23.507,73.058
	c-0.047,12.453-0.184,40.847-0.473,47.005V318.83c0,7.619-6.177,13.798-13.798,13.798c-7.621,0-13.798-6.179-13.798-13.798V200.517
	c0-0.382,0.017-0.766,0.048-1.145c0.185-2.921,0.362-28.415,0.425-45.556c0-31.285,9.837-61.997,28.446-88.762l32.531-46.789
	C229.005,6.829,242.069,0,255.999,0c13.93,0,26.994,6.829,34.947,18.267l32.531,46.787c18.609,26.765,28.446,57.476,28.446,88.813
	c0.063,17.089,0.24,42.58,0.432,45.588c0.032,0.381,0.041,0.676,0.041,1.06V318.83C352.395,326.451,346.218,332.628,338.598,332.628
	z"
      />
      <circle style="fill: #444" cx="256.005" cy="139.165" r="40.884" />
      <g>
        <path
          style="fill: #a6e90b"
          d="M255.999,193.854c-30.152,0-54.682-24.53-54.682-54.682s24.53-54.682,54.682-54.682
		s54.682,24.53,54.682,54.682S286.152,193.854,255.999,193.854z M255.999,112.086c-14.936,0-27.087,12.152-27.087,27.087
		s12.15,27.087,27.087,27.087c14.936,0,27.087-12.152,27.087-27.087S270.936,112.086,255.999,112.086z"
        />
        <path
          style="fill: #fff"
          d="M343.31,394.05h-45.917c-7.621,0-13.798-6.179-13.798-13.798c0-7.619,6.177-13.798,13.798-13.798
		h45.917c5.522,0,10.014-4.493,10.014-10.014s-4.493-10.014-10.014-10.014h-174.62c-5.522,0-10.014,4.493-10.014,10.014
		s4.493,10.014,10.014,10.014h45.917c7.621,0,13.798,6.179,13.798,13.798c0,7.619-6.177,13.798-13.798,13.798h-45.917
		c-20.738,0-37.61-16.872-37.61-37.61s16.872-37.61,37.61-37.61h174.622c20.738,0,37.61,16.872,37.61,37.61
		S364.048,394.05,343.31,394.05z"
        />
      </g>
      <path
        style="fill: #444"
        d="M234.088,419.145c-10.467,0-19.296,7.707-20.813,18.064c-0.69,4.715-0.955,9.614-0.511,14.449
	c1.86,20.266,20.781,35.676,32.851,43.535c6.081,3.96,13.943,4.015,20.08,0.143c12.181-7.687,31.32-22.823,33.469-43.062
	c0.528-4.98,0.297-10.038-0.386-14.9c-1.465-10.428-10.304-18.231-20.835-18.231h-43.857V419.145z"
      />
      <path
        style="fill: #a6e90b"
        d="M255.768,512L255.768,512c-6.299,0-12.413-1.813-17.681-5.243
	c-16.557-10.782-36.773-28.875-39.063-53.838c-0.511-5.558-0.308-11.516,0.599-17.711c2.493-17.024,17.309-29.863,34.466-29.863
	h43.854c17.256,0,32.087,12.944,34.499,30.108c0.9,6.415,1.049,12.563,0.443,18.277c-2.646,24.93-23.118,42.731-39.826,53.272
	C267.879,510.273,261.899,512,255.768,512z M234.088,432.942c-3.618,0-6.63,2.634-7.161,6.266c-0.592,4.039-0.734,7.804-0.424,11.19
	c1.399,15.247,19.096,28.319,26.641,33.232c0.777,0.505,1.685,0.774,2.623,0.774l0,0c0.913,0,1.801-0.255,2.564-0.737
	c7.614-4.803,25.494-17.621,27.11-32.849c0.368-3.47,0.258-7.347-0.328-11.525c-0.517-3.68-3.534-6.353-7.172-6.353h-43.852V432.942
	z"
      />
      <rect
        x="173.397"
        y="277.434"
        style="fill: #fff"
        width="165.202"
        height="55.191"
      />
      <g>
        <path
          style="fill: #fff"
          d="M338.598,346.426H173.401c-7.621,0-13.798-6.179-13.798-13.798v-55.191
		c0-7.619,6.177-13.798,13.798-13.798h165.198c7.621,0,13.798,6.179,13.798,13.798v55.191
		C352.395,340.248,346.218,346.426,338.598,346.426z M187.199,318.83h137.602v-27.596H187.199V318.83z"
        />
        <path
          style="fill: #fff"
          d="M398.068,332.628c-3.532,0-7.062-1.348-9.756-4.041c-5.388-5.388-5.388-14.125,0-19.514
		l18.554-18.553l-78.957-101.18c-4.687-6.008-3.618-14.68,2.39-19.367c6.006-4.687,14.678-3.618,19.367,2.388l79.851,102.326
		c8.284,9.83,7.691,24.517-1.434,33.642l-20.258,20.257C405.131,331.281,401.599,332.628,398.068,332.628z"
        />
        <path
          style="fill: #fff"
          d="M113.931,332.628c-3.531,0-7.062-1.348-9.756-4.041l-20.257-20.255
		c-9.125-9.125-9.718-23.812-1.435-33.642l79.851-102.326c4.689-6.008,13.358-7.077,19.367-2.388
		c6.008,4.687,7.077,13.359,2.39,19.367l-78.957,101.18l18.554,18.553c5.388,5.388,5.389,14.125,0,19.514
		C120.994,331.281,117.462,332.628,113.931,332.628z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.fil1 {
  fill: #a6e90b;
}
</style>
