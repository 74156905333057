<template>
  <div class="bg-dark pt-5 position-absolute">
    <div class="row text-white justify-content-center m-5">
      <ModuleBox
        title="Audicomp e Triagem"
        content="Sistema de auditoria comportamental e triagem"
        link="https://vixplancon.com.br/index.php?menu=colab"
        iconBox="astronaut-talk.jpg"
      />
      <ModuleBox
        title="Sucata"
        content="Sistema de sucateamento e alienação, que acompanha desde o recebimento da demanda via planilhas (ISA) do GOPI  até a medição"
        link="https://sucata.vixplancon.com.br/public/"
        iconBox="astronaut-box.jpg"
      />
      <ModuleBox
        title="Preserva (Teste)"
        content="Sistema de controle do processo de inspeção e preservação de materiais"
        link="https://teste.vixplancon.com.br/public/"
        iconBox="astronaut-jet.jpg"
      />
      <ModuleBox
        title="Prepara"
        content="Sistema que abrange o processo desde a coleta até a unitização dos itens de uma requisição de transporte"
        link="#"
        iconBox="astronaut-box.jpg"
      />
      <ModuleBox
        title="Frota Web"
        content="Controle e requisição de veículos e equipamentos para movimentação de carga"
        link="https://frotaweb.vixplancon.com.br/"
        iconBox="astronaut-world.jpg"
      />
    </div>
     <div class="bg-svg"> 
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ff0"
          fill-opacity="1"
          d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import ModuleBox from "../components/ModuleBox";
export default {
  name: "Profile",
  components: {
    ModuleBox,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
  .bg-svg{
    position: relative;
    margin-bottom: 0px;
  }
</style>
