import axios from "axios";
// import authHeader from './auth-header';

const API_URL = "https://vixplancon.herokuapp.com/users/";

class UserService {
  getPublicContent() {
    return "";
    // return axios.get(API_URL + 'all');
  }

  // getUserBoard() {
  //   return axios.get(API_URL + 'user', { headers: authHeader() });
  // }

  // getModeratorBoard() {
  //   return axios.get(API_URL + 'mod', { headers: authHeader() });
  // }

  // getAdminBoard() {
  //   return axios.get(API_URL + 'admin', { headers: authHeader() });
  // }

  async getCurrentUserData(token) {
    return await axios.get(API_URL + "me/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
}

export default new UserService();
