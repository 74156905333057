<template>
  <div id="app">
    <default-layout />
  </div>
</template>

<script>
import DefaultLayout from './layouts/DefaultLayout.vue';
import "./assets/css/global.css"
export default {
  components: {DefaultLayout },
};
</script>
<style>
</style>
