<template>
  <div class="ModuleBox">
    <div class="text-center">
      <h4 class="titleBox w-100">
        {{title}}
      </h4>
      <div class="contentBox row">
        <div class="imgBox col-6"> <img :src="resolve_img_url(iconBox)" alt=""></div>
        <div class="textBox col-6">  {{content}}</div>
      </div>
      <a v-bind:href="link" class="btn w-100 bg-dark buttonBox">Abrir</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  props:{
    title: String,
    content: String,
    link: String,
    iconBox: String,
  },
  methods: {
    resolve_img_url: function (path) {
      let images = require.context('../assets/img/', false, /\.png$|\.jpg$/)
      return images("./"+path)
    }
  },
  setup() {
    return {imgBox2 : '@/assets/img/astronaut-talk.jpg'};
  },
};
</script>

<style scoped>

.imgBox img{
  height: 80% !important;
  width: 80% !important;
  padding: 0;
  margin-top: 10px;
  border-radius: 1rem;
  box-shadow: 0 3px 8px 1px rgba(7, 7, 7, 0.6);
}

.textBox{
  min-height: 80px !important;
  max-height: 150px !important;
  vertical-align: middle;
  padding: 0;
  margin: 0;
}

.ModuleBox {
  min-width: 360px !important;
  max-width: 360px !important;
  height: 280px !important;
  display: block;
  position: relative;
  background: rgba(255, 253, 253, 0.8);
  box-shadow: 4px 2px 24px 1px rgba(7, 7, 7, 0.6);
  border-radius: 10px 5%;
  margin-bottom: 10px;
  margin: 8px;
  color: rgb(34, 33, 33);
}



.titleBox{
  height: 40px !important;
  width: 100% !important;
  color: rgb(27, 26, 26) !important;
  margin: 12px 0 12px 0;
  font-weight: bold;
}

.contentBox{
  min-height: 150px !important;
  max-height: 150px !important;
  width: 100% !important;
  overflow: hidden;
}

.buttonBox{
  height: 40px !important;
  width: 100% !important;
  margin-top: 12px ;
  color: rgb(165, 233, 7) !important;
  box-shadow: 4px 2px 24px 1px rgba(7, 7, 7, 0.6);
  
}

.buttonBox:hover{
  color: rgb(27, 26, 26) !important;
  background: rgb(165, 233, 7) !important;

}



</style>