<template>
  <div class="">
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 242.11 111.29"
    >
      <defs>
        <radialGradient
          id="radial-gradient"
          cx="389.68"
          cy="111.29"
          r="1"
          gradientTransform="matrix(53.46, 0, 0, -53.46, -20735.32, 6002.57)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset="0.24" stop-color="#c3c3c3" />
          <stop offset="0.8" stop-color="#2f2f2f" />
          <stop offset="0.98" />
          <stop offset="1" />
        </radialGradient>
        <linearGradient
          id="linear-gradient"
          x1="393.68"
          y1="114.72"
          x2="394.68"
          y2="114.72"
          gradientTransform="matrix(81.84, 0, 0, -81.84, -32129.77, 9445.45)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" />
          <stop offset="1" stop-color="#fff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="388.59"
          y1="119.81"
          x2="389.59"
          y2="119.81"
          gradientTransform="matrix(32.96, 0, 0, -32.96, -12772.34, 4021.12)"
          xlink:href="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="394.18"
          y1="115.63"
          x2="395.18"
          y2="115.63"
          gradientTransform="matrix(67.71, 0, 0, -67.71, -26668.93, 7884.89)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" />
          <stop offset="0.09" stop-color="#1d1d1d" />
          <stop offset="0.35" stop-color="#6c6c6c" />
          <stop offset="0.57" stop-color="#ababab" />
          <stop offset="0.77" stop-color="#d9d9d9" />
          <stop offset="0.91" stop-color="#f5f5f5" />
          <stop offset="1" stop-color="#fff" />
        </linearGradient>
        <clipPath id="clip-path">
          <rect class="cls-5" width="242.11" height="111.29" />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            class="cls-5"
            d="M95.14,0A53.46,53.46,0,1,0,148.6,53.45,53.45,53.45,0,0,0,95.14,0Z"
          />
        </clipPath>
        <mask
          id="mask"
          x="41.69"
          y="0"
          width="106.91"
          height="106.91"
          maskUnits="userSpaceOnUse"
        >
          <rect class="cls-1" x="41.69" width="106.91" height="106.91" />
        </mask>
        <radialGradient
          id="radial-gradient-2"
          cx="389.68"
          cy="111.29"
          r="1"
          gradientTransform="matrix(53.46, 0, 0, -53.46, -20735.32, 6002.57)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#8d5b8c" />
          <stop offset="0.54" stop-color="#553f58" />
          <stop offset="0.98" stop-color="#24272b" />
          <stop offset="1" stop-color="#24272b" />
        </radialGradient>
        <clipPath id="clip-path-5">
          <rect
            class="cls-5"
            x="89.15"
            y="15.54"
            width="81.84"
            height="81.84"
          />
        </clipPath>
        <clipPath id="clip-path-6">
          <path
            class="cls-5"
            d="M171,56.47a40.92,40.92,0,1,0-40.92,40.92A40.92,40.92,0,0,0,171,56.47"
          />
        </clipPath>
        <mask
          id="mask-2"
          x="48.23"
          y="-25.37"
          width="163.68"
          height="163.68"
          maskUnits="userSpaceOnUse"
        >
          <rect
            class="cls-2"
            x="72.2"
            y="-1.4"
            width="115.74"
            height="115.74"
            transform="translate(-1.83 108.51) rotate(-45)"
          />
        </mask>
        <linearGradient
          id="linear-gradient-4"
          x1="393.68"
          y1="114.72"
          x2="394.68"
          y2="114.72"
          gradientTransform="matrix(81.84, 0, 0, -81.84, -32129.77, 9445.45)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#24272b" />
          <stop offset="1" stop-color="#131212" />
        </linearGradient>
        <clipPath id="clip-path-7">
          <rect
            class="cls-5"
            x="34.18"
            y="56.06"
            width="32.96"
            height="32.96"
          />
        </clipPath>
        <clipPath id="clip-path-8">
          <path
            class="cls-5"
            d="M67.14,72.54A16.48,16.48,0,1,0,50.66,89,16.48,16.48,0,0,0,67.14,72.54"
          />
        </clipPath>
        <mask
          id="mask-3"
          x="17.71"
          y="39.59"
          width="65.91"
          height="65.91"
          maskUnits="userSpaceOnUse"
        >
          <rect
            class="cls-3"
            x="27.36"
            y="49.24"
            width="46.61"
            height="46.61"
            transform="translate(-36.46 57.07) rotate(-45)"
          />
        </mask>
        <linearGradient
          id="linear-gradient-5"
          x1="388.59"
          y1="119.81"
          x2="389.59"
          y2="119.81"
          gradientTransform="matrix(32.96, 0, 0, -32.96, -12772.34, 4021.12)"
          xlink:href="#linear-gradient-4"
        />
        <clipPath id="clip-path-9">
          <rect class="cls-5" x="203.14" y="32.11" width="4.38" height="4.38" />
        </clipPath>
        <clipPath id="clip-path-10">
          <rect class="cls-5" y="56.95" width="4.38" height="4.38" />
        </clipPath>
        <clipPath id="clip-path-11">
          <rect class="cls-5" x="239.19" y="66.21" width="2.92" height="2.92" />
        </clipPath>
        <clipPath id="clip-path-12">
          <rect
            class="cls-5"
            x="121.75"
            y="21.61"
            width="14.87"
            height="7.83"
          />
        </clipPath>
        <clipPath id="clip-path-13">
          <rect class="cls-5" x="54.35" y="61.44" width="6.21" height="4.64" />
        </clipPath>
        <clipPath id="clip-path-14">
          <rect class="cls-5" x="156" y="67.21" width="5.6" height="8.2" />
        </clipPath>
        <clipPath id="clip-path-15">
          <rect class="cls-5" x="152.14" y="55.52" width="5.52" height="8.88" />
        </clipPath>
        <clipPath id="clip-path-16">
          <path
            class="cls-5"
            d="M99.85,40.78c-1.47,3.78-4.91,6.33-7.77,6.76s-3.89,6.31,3,4.77c0,0,8.37-2.78,10.35-8.29,1.24-3.45-.68-5.33-2.65-5.33a3.11,3.11,0,0,0-2.93,2.09"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient-6"
          x1="379.48"
          y1="122.84"
          x2="380.48"
          y2="122.84"
          gradientTransform="matrix(18.85, 0, 0, -18.85, -7062.99, 2361.33)"
          xlink:href="#linear-gradient-4"
        />
        <clipPath id="clip-path-18">
          <path
            class="cls-5"
            d="M98.86,59.73C95.52,61.58,98,65,99.93,65.3a5.94,5.94,0,0,0,2.4-.37c1.63-.45,3.42-.89,4.65.84a1.69,1.69,0,0,0,.38.42c1.79,1.69,5.67,1.18,6.14-1.65a4.85,4.85,0,0,0-.36-2.48c-1.15-3-4-4.24-7.16-4.24a15,15,0,0,0-7.12,1.91"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient-7"
          x1="372.77"
          y1="111.27"
          x2="373.77"
          y2="111.27"
          gradientTransform="matrix(16.31, 0, 0, -16.31, -5984.47, 1878.2)"
          xlink:href="#linear-gradient-4"
        />
        <clipPath id="clip-path-19">
          <path
            class="cls-5"
            d="M74.49,61.68c5,7.11,19.05,16.83,25.32,6.8s-9.27-19-16.34-19.42H83c-6.8,0-10.51,9.83-8.55,12.63"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient-8"
          x1="384.9"
          y1="104.19"
          x2="385.9"
          y2="104.19"
          gradientTransform="matrix(0, 28.17, 28.17, 0, -2845.41, -10794.25)"
          xlink:href="#linear-gradient-4"
        />
        <clipPath id="clip-path-20">
          <path
            class="cls-5"
            d="M64.25,69.33c1.57,5.81,7.48,5.38,7.37-.17S77.52,65.79,78,64s-2.28-4.27-2.28-4.27a5.94,5.94,0,0,0-2.62-.59c-4.85,0-10.18,5.32-8.86,10.21"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient-9"
          x1="368.79"
          y1="111.23"
          x2="369.79"
          y2="111.23"
          gradientTransform="matrix(14.02, 0, 0, -14.02, -5106.6, 1626.7)"
          xlink:href="#linear-gradient-4"
        />
        <clipPath id="clip-path-21">
          <path
            class="cls-5"
            d="M91.91,72.69c1.3,3.13.58,5.64,4.53,10.84a4.76,4.76,0,0,0,1,1h0a4.24,4.24,0,0,0,6.41-3.27c.13-1.64-.69-3.67-3.11-5.8-2.93-2.59-2.43-4.34-3.93-5.56a3.45,3.45,0,0,0-2.1-.59c-1.83,0-3.83,1-2.81,3.42"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient-10"
          x1="397.11"
          y1="86.21"
          x2="398.11"
          y2="86.21"
          gradientTransform="matrix(0, 15.85, 15.85, 0, -1268.59, -6223.07)"
          xlink:href="#linear-gradient-4"
        />
        <clipPath id="clip-path-22">
          <path
            class="cls-5"
            d="M72.38,33.4C64.55,38.22,61.7,47.8,66,54.8s14.15,8.75,22,3.92,10.68-14.4,6.37-21.4A14.48,14.48,0,0,0,81.8,30.68a18,18,0,0,0-9.42,2.72"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient-11"
          x1="384.84"
          y1="111.26"
          x2="385.84"
          y2="111.26"
          gradientTransform="matrix(32.37, 0, 0, -32.37, -12394.18, 3648.81)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#131212" />
          <stop offset="1" stop-color="#24272b" />
        </linearGradient>
        <mask
          id="mask-4"
          x="-48.19"
          y="-104.84"
          width="338.49"
          height="320.97"
          maskUnits="userSpaceOnUse"
        >
          <rect
            class="cls-4"
            x="12.34"
            y="-77.01"
            width="217.44"
            height="265.32"
            transform="translate(12.34 132.66) rotate(-60)"
          />
        </mask>
        <linearGradient
          id="linear-gradient-12"
          x1="394.18"
          y1="116.37"
          x2="395.18"
          y2="116.37"
          gradientTransform="matrix(33.86, -58.64, -58.64, -33.86, -6493.63, 27171.07)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#24272b" />
          <stop offset="0" stop-color="#24282c" />
          <stop offset="0.35" stop-color="#3a666a" />
          <stop offset="0.64" stop-color="#4a9397" />
          <stop offset="0.87" stop-color="#54afb3" />
          <stop offset="1" stop-color="#58b9bd" />
        </linearGradient>
      </defs>
      <title>Artboard 2</title>
      <g class="cls-6">
        <g class="cls-6">
          <g class="cls-7">
            <g class="cls-8">
              <rect class="cls-9" x="41.69" width="106.91" height="106.91" />
            </g>
          </g>
          <g class="cls-6">
            <g class="cls-10">
              <g class="cls-11">
                <g class="cls-12">
                  <g class="cls-13">
                    <rect
                      class="cls-14"
                      x="72.2"
                      y="-1.4"
                      width="115.74"
                      height="115.74"
                      transform="translate(-1.83 108.51) rotate(-45)"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g class="cls-10">
              <g class="cls-15">
                <g class="cls-16">
                  <g class="cls-17">
                    <rect
                      class="cls-18"
                      x="27.36"
                      y="49.24"
                      width="46.61"
                      height="46.61"
                      transform="translate(-36.46 57.07) rotate(-45)"
                    />
                  </g>
                </g>
              </g>
            </g>
            <path
              class="cls-19"
              d="M43.84,28.45a1.22,1.22,0,1,1-1.22-1.21,1.21,1.21,0,0,1,1.22,1.21"
            />
            <g class="cls-20">
              <g class="cls-21">
                <path
                  class="cls-19"
                  d="M207.52,34.3a2.19,2.19,0,1,1-2.19-2.19,2.19,2.19,0,0,1,2.19,2.19"
                />
              </g>
            </g>
            <g class="cls-10">
              <g class="cls-22">
                <path
                  class="cls-19"
                  d="M4.38,59.14A2.19,2.19,0,1,1,2.19,57a2.2,2.2,0,0,1,2.19,2.19"
                />
              </g>
            </g>
            <path
              class="cls-19"
              d="M162.71,91.05a2,2,0,1,0-2,2,2,2,0,0,0,2-2"
            />
            <g class="cls-10">
              <g class="cls-23">
                <path
                  class="cls-19"
                  d="M242.11,67.67a1.46,1.46,0,1,0-1.46,1.46,1.46,1.46,0,0,0,1.46-1.46"
                />
              </g>
            </g>
            <g class="cls-20">
              <g class="cls-24">
                <path
                  class="cls-19"
                  d="M132,21.8c-7.07-1.46-15.19,6-6.31,7.45s16-5.45,6.31-7.45"
                />
              </g>
            </g>
            <g class="cls-20">
              <g class="cls-25">
                <path
                  class="cls-19"
                  d="M59.6,63.13c-2.15-2.56-7.24-2.18-4.43.89s7.39,2.64,4.43-.89"
                />
              </g>
            </g>
            <g class="cls-20">
              <g class="cls-26">
                <path
                  class="cls-19"
                  d="M159.82,74c3-3,2-9.46-1.61-5.55s-2.58,9.69,1.61,5.55"
                />
              </g>
            </g>
            <g class="cls-20">
              <g class="cls-27">
                <path
                  class="cls-19"
                  d="M156.83,62.53c2.37-3.55-.87-10-3.63-5.43s.37,10.33,3.63,5.43"
                />
              </g>
            </g>
            <path
              class="cls-19"
              d="M130.76,40.79a16.27,16.27,0,0,1,1.93-1.12,12.53,12.53,0,0,1,2.2-.84,12.8,12.8,0,0,1,2.49-.44,14.44,14.44,0,0,1,2.84.08,12,12,0,0,1,3.66,1,8.5,8.5,0,0,1,2.68,2,7.33,7.33,0,0,1,1.54,2.77,9.05,9.05,0,0,1-.68,6.43,8.62,8.62,0,0,1-1.58,2.11,10.15,10.15,0,0,1-1.92,1.46L142,55.36a14.43,14.43,0,0,0-1.58,1,2.44,2.44,0,0,0-.89,1.26l-1,3.37-4-.5.09-3.89a3.22,3.22,0,0,1,.56-1.91,6.18,6.18,0,0,1,1.42-1.37,17.64,17.64,0,0,1,1.88-1.13,16.43,16.43,0,0,0,1.94-1.18,7.6,7.6,0,0,0,1.6-1.55,4.46,4.46,0,0,0,.84-2.2,3.63,3.63,0,0,0-.12-1.56,3.45,3.45,0,0,0-.74-1.27,4.08,4.08,0,0,0-1.26-.91,5.5,5.5,0,0,0-1.67-.48,7.69,7.69,0,0,0-2.31,0,8.66,8.66,0,0,0-1.67.46c-.46.19-.85.36-1.17.52a1.52,1.52,0,0,1-.84.19,1.29,1.29,0,0,1-1.14-.86Zm.93,28.33a3.75,3.75,0,0,1,.44-1.36,3.57,3.57,0,0,1,.89-1,3.38,3.38,0,0,1,1.23-.61,3.47,3.47,0,0,1,1.44-.11,3.64,3.64,0,0,1,1.37.46,3.91,3.91,0,0,1,1,.9,3.5,3.5,0,0,1,.73,2.65,3.48,3.48,0,0,1-.46,1.37,3.41,3.41,0,0,1-.9,1,3.91,3.91,0,0,1-1.23.6,3.62,3.62,0,0,1-1.44.09,3.52,3.52,0,0,1-1.37-.45,3.32,3.32,0,0,1-1-.88,3.66,3.66,0,0,1-.6-1.22,3.41,3.41,0,0,1-.09-1.43"
            />
            <path
              class="cls-19"
              d="M45.84,71.38a6.4,6.4,0,0,1,.4-.84,5.26,5.26,0,0,1,.55-.81,6,6,0,0,1,.76-.74,5.28,5.28,0,0,1,1-.64,5.13,5.13,0,0,1,1.5-.51,3.58,3.58,0,0,1,1.39,0,3,3,0,0,1,1.18.58,3.49,3.49,0,0,1,.88,1.11,3.39,3.39,0,0,1,.36,2.36,4.23,4.23,0,0,1-.31,1l-.4.83a6.48,6.48,0,0,0-.29.73,1,1,0,0,0,0,.65l.46,1.38-1.48.77-.89-1.35a1.29,1.29,0,0,1-.26-.79,2.65,2.65,0,0,1,.16-.81,7.48,7.48,0,0,1,.38-.83,7.34,7.34,0,0,0,.39-.86,3.58,3.58,0,0,0,.18-.91,1.87,1.87,0,0,0-.23-1,1.62,1.62,0,0,0-.41-.51,1.44,1.44,0,0,0-.56-.26,1.53,1.53,0,0,0-.64,0,2.08,2.08,0,0,0-.69.23,3,3,0,0,0-.79.55,2.74,2.74,0,0,0-.46.55,4.36,4.36,0,0,0-.28.45.74.74,0,0,1-.25.27.53.53,0,0,1-.59,0Zm7,9.5a1.55,1.55,0,0,1-.17-.57,1.36,1.36,0,0,1,.06-.57,1.53,1.53,0,0,1,.27-.5,1.65,1.65,0,0,1,.48-.38,1.48,1.48,0,0,1,.57-.16,1.78,1.78,0,0,1,.57.06,1.46,1.46,0,0,1,.5.28,1.42,1.42,0,0,1,.38.46,1.49,1.49,0,0,1,.09,1.14,1.5,1.5,0,0,1-.75.87,1.75,1.75,0,0,1-.58.17,1.33,1.33,0,0,1-.56-.06,1.57,1.57,0,0,1-.49-.27,1.54,1.54,0,0,1-.37-.47"
            />
          </g>
          <g class="cls-28">
            <rect
              class="cls-29"
              x="85.54"
              y="34.86"
              width="23.77"
              height="22.81"
              transform="translate(-9.43 60.88) rotate(-33.19)"
            />
          </g>
          <g class="cls-6">
            <path
              class="cls-30"
              d="M76.18,64.27c-2.26,3.56,1.83,7.4,4.73,9.29s5.75,3.31,8.75-2.27c.78-1.55-2-3.35-4.59-5.31s-6.63-5.28-8.89-1.71"
            />
          </g>
          <g class="cls-31">
            <rect
              class="cls-32"
              x="95.52"
              y="57.82"
              width="18.09"
              height="10.07"
            />
          </g>
          <g class="cls-33">
            <rect
              class="cls-34"
              x="68.16"
              y="41.89"
              width="42.28"
              height="43.79"
              transform="translate(-10.55 109.3) rotate(-60.04)"
            />
          </g>
          <g class="cls-35">
            <rect
              class="cls-36"
              x="62.93"
              y="59.12"
              width="15.57"
              height="16.02"
            />
          </g>
          <g class="cls-37">
            <rect
              class="cls-38"
              x="90.89"
              y="69.27"
              width="13.1"
              height="16.86"
            />
          </g>
          <g class="cls-39">
            <rect
              class="cls-40"
              x="61.7"
              y="30.68"
              width="36.97"
              height="32.87"
            />
          </g>
          <g class="cls-6">
            <path
              class="cls-30"
              d="M96.12,38.47c-3.5-5.68-11.5-7.11-17.86-3.19S69.57,47,73.07,52.67s11.5,7.12,17.87,3.19,8.68-11.7,5.18-17.39"
            />
            <path
              class="cls-30"
              d="M93,57.61c-3.08,2.88,1.5,7.43,3.87,5.2,3.09-2.9-1.47-7.45-3.87-5.2"
            />
            <path
              class="cls-30"
              d="M103.86,81.22a4.24,4.24,0,0,1-6.41,3.27c2-.18,4.83-.86,6.41-3.27"
            />
            <path
              class="cls-30"
              d="M113.49,64.54c-.46,2.83-4.34,3.34-6.13,1.65,1.68.07,4.35-.1,6.13-1.65"
            />
            <path
              class="cls-41"
              d="M87.28,54.36A18.21,18.21,0,0,1,99.21,68.53"
            />
            <g class="cls-42">
              <path
                class="cls-43"
                d="M80.69,70.2c-5.07,18-28.65,1.61-34.36,21.85-3.87,13-12.76,18.76-21.79,18.74h0a24.91,24.91,0,0,1-16.25-6.38.5.5,0,0,1,0-.71.5.5,0,0,1,.7,0,23.93,23.93,0,0,0,15.59,6.13c8.6,0,17-5.31,20.83-18C52,70.59,75.81,86.77,79.72,70a.5.5,0,1,1,1,.25Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.cls-1 {
  fill: url(#radial-gradient);
}
.cls-2 {
  fill: url(#linear-gradient);
}
.cls-3 {
  fill: url(#linear-gradient-2);
}
.cls-4 {
  fill: url(#linear-gradient-3);
}
.cls-41,
.cls-5 {
  fill: none;
}
.cls-6 {
  clip-path: url(#clip-path);
}
.cls-7 {
  clip-path: url(#clip-path-3);
}
.cls-8 {
  mask: url(#mask);
}
.cls-9 {
  fill: url(#radial-gradient-2);
}
.cls-10 {
  opacity: 0.5;
}
.cls-11 {
  clip-path: url(#clip-path-5);
}
.cls-12 {
  clip-path: url(#clip-path-6);
}
.cls-13 {
  mask: url(#mask-2);
}
.cls-14 {
  fill: url(#linear-gradient-4);
}
.cls-15 {
  clip-path: url(#clip-path-7);
}
.cls-16 {
  clip-path: url(#clip-path-8);
}
.cls-17 {
  mask: url(#mask-3);
}
.cls-18 {
  fill: url(#linear-gradient-5);
}
.cls-19 {
  fill: #24272b;
}
.cls-20 {
  opacity: 0.75;
}
.cls-21 {
  clip-path: url(#clip-path-9);
}
.cls-22 {
  clip-path: url(#clip-path-10);
}
.cls-23 {
  clip-path: url(#clip-path-11);
}
.cls-24 {
  clip-path: url(#clip-path-12);
}
.cls-25 {
  clip-path: url(#clip-path-13);
}
.cls-26 {
  clip-path: url(#clip-path-14);
}
.cls-27 {
  clip-path: url(#clip-path-15);
}
.cls-28 {
  clip-path: url(#clip-path-16);
}
.cls-29 {
  fill: url(#linear-gradient-6);
}
.cls-30 {
  fill: #aeeb08;;
}
.cls-31 {
  clip-path: url(#clip-path-18);
}
.cls-32 {
  fill: url(#linear-gradient-7);
}
.cls-33 {
  clip-path: url(#clip-path-19);
}
.cls-34 {
  fill: url(#linear-gradient-8);
}
.cls-35 {
  clip-path: url(#clip-path-20);
}
.cls-36 {
  fill: url(#linear-gradient-9);
}
.cls-37 {
  clip-path: url(#clip-path-21);
}
.cls-38 {
  fill: url(#linear-gradient-10);
}
.cls-39 {
  clip-path: url(#clip-path-22);
}
.cls-40 {
  fill: url(#linear-gradient-11);
}
.cls-41 {
  stroke: #aeeb08;
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
}
.cls-42 {
  mask: url(#mask-4);
}
.cls-43 {
  fill: url(#linear-gradient-4);
}
</style>
