<template>
  <div class="parallax"></div>
   <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
  />
  <div class="fixed-top text-center page-title" style="top: 40%">  
      <span class="title-animation">PLANCON</span>
  </div>
  <div class="h-100 m-0 p-0 bg-black-gradient position-relative text-white">
    <div class="row p-4 justify-content-center text-center m-auto">
      <div class="col-md-4 m-auto animate__animated animate__fadeIn">
        <h4 class="pt-2"> <font-awesome-icon icon="medal" /> Missão</h4>
        <p>
          Fornecer soluções que auxiliem nas tomadas de decisões, possibilitando
          intervenções ágeis e eficientes para otimização dos indicadores de
          performace da empresa.
        </p>
      </div>
      <div class="col-md-4">
        <AstronautHelmet />
      </div>
      <div class="col-md-4 m-auto animate__animated animate__fadeIn">
        <h4 class="pt-2"><font-awesome-icon icon="crosshairs" /> Visão</h4>
        <p>
          Alcançar o reconhecimento pela qualidade e comprometimento na entrega
          de soluções, simplificando processos robustos através da tecnologia e
          inovação.
        </p>
      </div>
      <div class="col-12 text-center m-auto animate__animated animate__fadeIn">
        <h4 class="pt-2"><font-awesome-icon icon="gem" /> Valores</h4>
        <div class="row text-center justify-content-center " >
          Segurança, inovação, transparência, ética, <br />
          foco, comprometimento e interdependência
        </div>
      </div>
    </div>
    <div class="col-12  ">
      <Astronaut />
    </div>
  </div>
</template>

<script>
import "animate.css";
// import UserService from "../services/user.service";
import Astronaut from "../components/Astronaut.vue";
import AstronautHelmet from "../components/AstronautHelmet.vue";

// import TextBox from "../components/TextBox.vue";

export default {
  name: "Home",
  data() {
    return {
      content: "",
    };
  },
  components: {
    Astronaut,
    AstronautHelmet,
    // TextBox,
  },
  methods:{
  },
  mounted() {
    // UserService.getPublicContent().then(
    //   (response) => {
    //     this.content = response.data;
    //   },
    //   (error) => {
    //     this.content =
    //       (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //       error.message ||
    //       error.toString();
    //   }
    // );
  },
};
</script>

<style scoped>
.astronaut-img {
  background-color: rgba(34, 33, 33, 0.4);
}

.bg-black-gradient {
  background-image: radial-gradient(
    farthest-side at 50% 20%,
    rgba(0, 0, 0, 1),
    rgba(6, 1, 20, 1),
    rgba(0, 0, 0, 1)
  );
  z-index: 2;
}

.page-title {
  text-shadow: -6px 6px 10px rgb(0, 0, 0);
  font-family: Fantasy, Roboto, sans-serif !important;
  z-index: 1;
  font-size: 4rem;
  letter-spacing: 0.4rem;
  color: rgb(255, 255, 255) !important;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    animation: unset !important;
    transition: none !important;
  }
}

.title-animation {
  display: inline-block;
  margin: 0 0.5rem;

  animation: bounce; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}

.parallax {
  /* The image used */
  background-image: linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.25)),
    url("../assets/img/astronaut-parallax.jpg");

  /* Set a specific height */
  min-height: 600px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.92;
}
</style>
