<template>
    <nav class="navbar navbar-expand navbar-dark fixed-top">
      <div class="mx-2">
        <a href="#" class="navbar-brand"
          ><img src="@/assets/logo.png" widith="32" height="32" alt=""
        /></a>
      </div>
      <div class="navbar-nav m-auto">
        <li class="nav-item">
          <router-link v-if="!ishome" to="/home" class="nav-link">
            <font-awesome-icon icon="home" /> Início
          </router-link>
        </li>
        <li class="nav-item">
          <router-link v-if="currentUser" to="/modules" class="nav-link">
            <font-awesome-icon icon="th" /> Módulos
          </router-link>
        </li>
        <!--<li v-if="showAdminBoard" class="nav-item">
          <router-link to="/admin" class="nav-link">Admin</router-link>
        </li>
        <li v-if="showModeratorBoard" class="nav-item">
          <router-link to="/mod" class="nav-link">Moderator</router-link>
        </li>-->
        <!--<li class="nav-item">
          <router-link v-if="currentUser" to="/user" class="nav-link"
            >Usuário</router-link
          >
        </li>-->
      </div>

      <div v-if="!currentUser" class="navbar-nav ml-auto">
        <!-- <li class="nav-item">
          <router-link to="/register" class="nav-link">
            <font-awesome-icon icon="user-plus" /> Cadastrar-se
          </router-link>
        </li> -->
        <li class="nav-item">
          <router-link to="/login" class="nav-link text-link">
            <font-awesome-icon icon="sign-in-alt" /> Entrar
          </router-link>
        </li>
      </div>

      <div v-if="currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/profile" class="nav-link">
            <font-awesome-icon icon="user" />
            {{ currentUser.nome }}
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link text-link" @click.prevent="logOut">
            <font-awesome-icon icon="sign-out-alt" /> LogOut
          </a>
        </li>
      </div>
    </nav>
</template>

<script>
export default {
  computed: {
    currentUser() {
      
      return this.$store.state.auth.user;
    },
    ishome(){
      let currentroute = this.$route.path;
      if(currentroute == '/' || currentroute == "/home"){
        return true
      }
      return false
    }
    // showAdminBoard() {
    //   if (this.currentUser && this.currentUser["roles"]) {
    //     return this.currentUser["roles"].includes("ROLE_ADMIN");
    //   }

    //   return false;
    // },
    // showModeratorBoard() {
    //   if (this.currentUser && this.currentUser["roles"]) {
    //     return this.currentUser["roles"].includes("ROLE_MODERATOR");
    //   }

    //   return false;
    // },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.text-link {
  color: #aeeb08 !important;
  font-weight: bold;
}
</style>
