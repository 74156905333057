import axios from 'axios';

const API_URL = 'https://vixplancon.herokuapp.com/';
import authUser from './user.service';


class AuthService {
  async login(user) {
    const formData = new FormData();
    formData.append('username', user.username);
    formData.append	('password', user.password);
    const response = await axios
      .post(API_URL + 'token',
        formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    if (response.data.access_token) {
      localStorage.setItem('user', JSON.stringify(response.data));
      const { data } = await authUser.getCurrentUserData(response.data.access_token);
      data['access_token'] = response.data.access_token;

      user = data
    }
    return user;
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      userkey: user.userkey,
      level: user.level,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
